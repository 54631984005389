
.mdc {

  &-text-field {
    &--outlined {
      .mdc-notched-outline {
        --form-input-border-radius: 10px;

        &__leading {
          width: max(12px, var(--form-input-border-radius)) !important;
          border-top-left-radius: var(--form-input-border-radius) !important;
          border-bottom-left-radius: var(--form-input-border-radius) !important;
        }

        &__trailing {
          width: max(12px, var(--form-input-border-radius)) !important;
          border-top-right-radius: var(--form-input-border-radius) !important;
          border-bottom-right-radius: var(--form-input-border-radius) !important;
        }
      }
    }
  }
}

.mat{
  --mat-option-selected-state-label-text-color: var(--primary-color);

  &-mdc {

    &-checkbox-checked {
      .mdc-checkbox__background {
        border-color: #147292 !important;
        background-color: #147292 !important;
      }
    }

    &-form-field {
      width: 100%;

      &-infix {
        min-height: 40px !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
      }
    }

    &-text-field {
      &-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-floating-label {
            top: 23px !important;
          }
        }
      }
    }

    &-icon-button{
      &.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 40px;
        width: var(--mdc-icon-button-state-layer-size);
        height: var(--mdc-icon-button-state-layer-size);
        padding: 5px;
      }
    }

    &-mini-fab:not(.mdc-fab--extended) {
      border-radius: 30% !important;
    }

    /*snackbar*/
    &-snack-bar-container {
      --mdc-snackbar-container-shape: 20px;

      &.mdc-snackbar{
        &--open{
          .mdc-snackbar__surface {
            padding: 5px 5px;
            border-radius: 15px;
            animation: move-up calc(calc(0.5 * 200ms) + 100ms) ease 0s forwards;
          }
        }
      }

      &.danger-snackbar {
        --mat-snack-bar-button-color: white;
        .mdc-snackbar__surface {
          background-color: var(--bs-danger);
          color: white;
        }
      }

      &.success-snackbar {
        --mat-snack-bar-button-color: white;
        .mdc-snackbar__surface {
          background-color: var(--bs-green);
          color: white;
        }
      }

      &-warning-snackbar{
        --mat-snack-bar-button-color: #333333;
        .mdc-snackbar__surface {
          background-color: var(--bs-warning);
          color: #333333;
        }
      }

      &-normal-snackbar{
        --mat-snack-bar-button-color: whitesmoke;
        .mdc-snackbar__surface {
          background-color: #333333;
          color: whitesmoke;
        }
      }

    }

    /*dialog*/
    &-dialog-container {
      //border: 15px solid #eeeeee2b;
      .mdc-dialog__surface {
        --mdc-dialog-container-shape: 15px;
        padding: 15px;
      }
    }

    &-paginator{
      background: transparent !important;

      &-container {
        min-height: 10px;
      }
    }

    &-tab-header {
      margin-bottom: 10px;
    }

  }

}

.cdk-global-overlay-wrapper{
  overflow-y: auto;
}

.cdk-overlay-container {
  z-index: 900 !important;
}

.timepicker-period {
  display: none !important
}

.clock-face__inner {
  display: none !important;
}

.bg-white{
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }
}

.no-hint{
  &.mat-mdc-form-field {
    margin-bottom: 5px !important;
  }
  .mat-mdc-form-field-subscript-wrapper{
    display: none !important;
  }
}
