body {
  margin: 0;
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
   &.bg-gradient{
     background: linear-gradient(256.7deg, #1f3c6a 0, #1bb4b7 100%) no-repeat fixed !important;
   }

}

:host {
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body { height: 100%; }

.hidden{
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}
.w-20{
  width: 20%;
}

.wh-100{
  width: 100px;
  height: 100px;
}

p, .mat-typography p {
  margin: 0;
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  color: #125699;
}

.cursor-pointer{
  cursor: pointer !important;

  &:focus, &:active {
    outline: none;
    border: none;
  }
}

.cursor-not-allowed{
  cursor: not-allowed !important;

  &:focus, &:active {
    outline: none;
    border: none;
  }
}


.mat-checkbox-layout {
  white-space: pre-wrap !important;
}

.cdk-drag-handle{
  cursor: move;
}

.border-radius-50 {
  border-radius: 50px;
}
.mat-stepper-horizontal, .mat-stepper-vertical{
  width: 100% !important;
}
ngx-material-timepicker {
  z-index: 1000;
}

.custom-modal-dialog{
  overflow-y: auto;
}

.cdk-global-overlay-wrapper, .mat-dialog-container {
  overflow-y: auto;
  border-top: 5px solid var(--mat-secondary-color);
}

.shadow-on-hover:hover {
  transform: translateY(-3px);
  transition: all .25s ease-in-out;
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;

  .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
  }

  .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card:not(.highlight-card) {
    cursor: pointer;
  }

}

.mat-elevation-z1 {
  box-shadow: 0 1.5px 3px rgba(0,0,0,0.16), 0 1.5px 3px rgba(0,0,0,0.23);
}

.mat-elevation-z6 {
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
}

.mat-header-row{
  background-color: #e2e2e2 !important;
  color: #333 !important;
  th.mat-header-cell{
    border: none;
  }
}
.mat-row{
  td.mat-cell{
    border: none;
  }
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 52px;
}

.table td {
  vertical-align: middle;
}

.swal2-popup {
  width: 24em !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-card-header .mat-card-subtitle:not(:first-child) {
  margin-top: -8px;
  color: #444242;
}

.mat-menu-panel{
  box-shadow: none;
  border: 1.5px solid #ddd;
}

.mat-menu-panel {
  min-width: 112px;
  max-width: 350px !important;

  .mat-menu-content {
    overflow-x: hidden;
  }

  .app-menu-item {
    min-height: 80px;
    text-align: center;
  }
}

.cdk-global-overlay-wrapper {
  overflow-y: auto !important;

  #cdk-overlay-0 {
    overflow-y: auto;
  }
}

.ql-snow {
  height: auto;
  background: #f8f8f8;
  border: none !important;
}

.ql-editor {
  background: white;
  box-shadow: rgba(60, 64, 67, 0.15) 0 1px 3px 1px;

  &.ql-blank {

    //&::before {
    //  content: 'Andika hapa ...';
    //  left: 65px !important;
    //}
  }
}

.ql-toolbar {
  background: white;
  border-bottom: 1px solid #e4d3d3 !important;
}

.mat-tab-list{
  .mat-tab-label{
    width: 100%;
  }
}
.mat-tab-body-content {
  padding: 15px 0;
  overflow-x: hidden !important;
}

.mat-select-search-panel, .mat-select-panel {
  overflow-x: hidden !important;
}

.simple-shadow{
  box-shadow: 0 2px 0 #ececec;
}

.form-control{
  border: none;
  &:active, &:hover, &:focus{
    border: none;
  }
}


.mat-form-field{
  width: 100%;
}

.inner-filter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center !important;
  align-items: center !important;
  .line-label {
    margin-top: 8px;
  }

  padding-bottom: 20px;
  border-bottom: 1px dashed #eaeaea;
}


.mat-drawer-content{
  z-index: 0 !important;
  height: 100vh !important;
}

mat-dialog-content{
  background: #F1F3F4;
  padding-top: 15px !important;
  margin-top: 0 !important;
  //max-height: 78vh !important;
}

ngx-dropzone{
  height: 80px !important;
}

.bolded-title{
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  opacity: .9;
  margin: 0;
  color: var(--main-color);
}

.mat-drawer-inner-container{
  overflow: hidden !important;
  //border-right: 1px solid rgba(0,0,0,.12);
  &:hover{
    transition: all .5s ease-in-out;
    overflow: auto !important;
  }
}


.ext-container {
  width: 100%;
  height: 100%;

  .inst-details {
    margin-bottom: 10px;
    justify-content: center;
    box-shadow: 0 4px 6px #c3c3c3;
    border-radius: 6px;
    border-left: 3px solid var(--secondary-color);
    padding: 10px;

    .title {
      color: var(--bs-dark);
      font-size: 15px;
      font-weight: 600;
    }

    .inst-details-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px;
      justify-content: flex-start;
    }

    .inst-details-item-container {
      padding: 10px;

      span {
        color: var(--primary-color);
        font-weight: 400;
      }
    }

  }

}

.z-index-1{
  z-index: 1;
}

.empty-icon{
  height: 200px !important;
  width: 200px !important;
  text-align: center;
  color: #dde1e3;
  font-size: 200px;
}

.vertical-overflow{
  height: 100vh;
  overflow-y: auto;
}


.marquee {
  width: 100vw;
  //padding: 0 500%;
  //margin: 0 -500%;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  /* top: -50px; */
  z-index: 9999999;

  &.z-index-0{
    z-index: 0;
  }

  .marquee-text {
    display: inline-block;
    animation: marquee 10s linear infinite;

    span {
      display: inline-block;
      font-size: 5rem;
      color: rgba(255,255,255, 0.15);
      letter-spacing: .01em;
      padding: .2em;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


// customer scroller
*::-webkit-scrollbar
{
  height: 5px;
  width: 7px;
  background-color: var(--secondary-color);
}

*::-webkit-scrollbar-thumb
{
  background-color: var(--secondary-color);
  border-radius: 5px;
}

#scrollbarTopStyle::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: var(--primary-color);
}

#scrollbarTopStyle::-webkit-scrollbar
{
  height: 1px;
  width: 1px;
  background-color: var(--primary-color);
}

#scrollbarTopStyle::-webkit-scrollbar-thumb
{
  background-color: #9f9d9d;
  border-radius: 5px;
}
