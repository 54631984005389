:host {
  .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}

#login-container {
  width: 100%;
  min-height: 100%;
  background-color: #efefef;
  //background-image: url('../../assets/images/login5.png');
  //background-size: cover;
  //background-repeat: no-repeat;

  .login-content {
    width: 100%;
    min-height: calc(100vh - 40px);
    padding: .8rem;

    .login-box {
      width: 60%;
      max-width: 500px;
      padding: 25px 25px;
      background: rgba(255, 255, 255, 0.55);
      backdrop-filter: blur(8px);
      border-radius: 1rem;
      box-shadow: 0 5px 15px #13131352;

      .logo-holder {
        text-align: center;

        img {
          width: 140px;
          height: 140px;
          padding: 10px;
          background: #fff;
          border-radius: 20%;
          margin-bottom: 2rem;
          border: 4px solid var(--primary-color);

          &+ h3 {
            color: var(--primary-color);
            margin: 0;
            font-weight: 800;
            font-size: 1.2rem;
            letter-spacing: .9px;
            word-spacing: 2px;
            text-transform: uppercase;
          }
        }
      }

      .login {
        padding: 25px 1.3rem 1.3rem;
        background: rgba(255, 255, 255, .95);
        border-radius: .5rem;
        margin-top: 1rem;
        border: 1px solid #aeaeae;
        .mat-icon {
          color: var(--primary-color);
        }
      }
    }
  }
}

@media(max-width: 768px) {
  #login-container {

    .login-content {
      .login-box {
        width: 70%;
      }
    }
  }
}

@media(max-width: 576px) {
  #login-container {

    .login-content {

      .login-box {
        width: 95%;
        padding: 10px 15px;
      }
    }
  }
}
