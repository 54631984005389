@use '@angular/material' as mat;

@include mat.core();

$custom-typography: mat.define-typography-config(
  $font-family: "Roboto",
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 500, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 500, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 500),
  $headline-5: mat.define-typography-level(24px, 32px, 500),
  $headline-6: mat.define-typography-level(16px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $subtitle-2: mat.define-typography-level(14px, 28px, 500),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 400),
);

// A Light theme
$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$cyan-palette, 600),
    accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette),
  ),
  typography: $custom-typography,
  density: 0,
));

// A dark theme
$dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$pink-palette),
    accent: mat.define-palette(mat.$blue-grey-palette),
    warn: mat.define-palette(mat.$red-palette),
  ),
  typography: $custom-typography,
  density: 0,
));

@include mat.all-component-themes($light-theme);

body[data-theme='dark']{
  @include mat.all-component-colors($dark-theme);
}

@import "assets/scss/fonts";
@import "assets/scss/bootstrap";
@import "./assets/scss/animation";
@import "assets/scss/custom-mdc";
@import "assets/scss/table";
@import "assets/scss/auth";
@import "assets/scss/style";
@import "assets/scss/graph";
@import "assets/scss/media-query/phone-media";
@import "assets/scss/media-query/general-media";



