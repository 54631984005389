.table{
  background: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-color: transparent;

  th, td {
    //padding: 0.75rem;
    vertical-align: middle;
    //border-top: 1px solid #dee2e6;
    white-space: normal !important;
    overflow-wrap: break-word;
    font-size: 14px;
  }

  thead{
    background-color: #d0e3e9 !important;

    tr{
      color: rgba(0, 0, 0, 0.75);
      font-size: 13px;
      //font-weight: 500;

      th{
        background: #d0e3e9;
        padding: 0.8rem 0.5rem;
        &:first-child{
          border-top-left-radius: 15px;
        }
        &:last-child{
          border-top-right-radius: 15px;
        }
      }
    }
  }

  tbody {
    --bs-table-bg: white;

    &:not(caption) > * > * {
      padding: 0.8rem 0.5rem;
      font-size: 14px;
      font-weight: normal;
    }

    tr {

      td{
        border-top: 2px solid rgba(208, 208, 208, 0.33);
      }

      &:hover {
        //transform: scale(1.01);
        //transform: translateY(-2px);
        box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
        //transition: all 0.2s;
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 15px;
          }
          &:last-child{
            border-bottom-right-radius: 15px;
          }
        }

      }

      ::ng-deep{
        td {
          //.action-icons {
          //  display: inline-flex;
          //  justify-content: center;
          //
          //  button{
          //    border: 0.1px solid #ddd;
          //    border-radius: 10px;
          //    padding: 1px;
          //    margin-right: 10px;
          //    width: 35px;
          //    height: 35px;
          //  }
          //
          //}
        }
      }

      .action-icons {
        display: inline-flex;
        justify-content: center;
        button{
          border: 0.1px solid #ddd;
          border-radius: 10px;
          padding: 3px;
          margin-right: 6px;
          //width: 40px;
          height: 40px;
        }

      }

    }
  }

}

mat-footer-row.hide {
  display: none !important;
}

::ng-deep{

  @media screen and (max-width: 992px) {
    table {
      thead{
        display: none !important;
      }
      tbody{
        tr{
          .table-header-sm{
            display: block !important;
            margin-bottom: 10px;
            font-size: 12px;
            font-weight: bold;
          }
        }

        th, td {
          display: block;
          width: 100%;
        }

      }
    }

  }
}

//::ng-deep{
//
//  @media screen and (max-width: 992px) {
//    table {
//      thead{
//        display: none !important;
//      }
//      tbody{
//        tr{
//          .table-header-sm{
//            display: block !important;
//            margin-bottom: 10px;
//            font-size: 12px;
//            font-weight: bold;
//          }
//        }
//
//        th, td {
//          display: block;
//          width: 100%;
//        }
//
//      }
//    }
//
//  }
//}
