
.dash-box{
  color: #697079;
  border: 1px solid rgb(189, 224, 193);
  margin: 0;
  place-self: stretch;
  box-shadow: rgba(116, 129, 141, 0.2) 0 3px 8px 0;
  align-items: center;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 15px;
  cursor: text;
  font-weight: 500;
  font-size: 0.9rem;
  transition: border 250ms ease 0s;
  position: relative;

  .count{
    margin-left: auto;
    font-size: 2rem;
    color: #714c07;
  }

  .dash-icon{
    position: absolute;
    top: 20px;
    right: 15px;
    color: #dfdad5;
    background: var(--primary-color);
    border-radius: 10px;
    height: 40px;
    width: 40px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    opacity: 0.7;

    @media screen and (max-width: 1240px) {
      display: none;
    }
  }

  &:hover{
    border-color: #ffbe46;
    background: #fff4d5;
    transform: scale(1.01);
    transition: all 0.25s ease-in;
    .dash-box{
      opacity: 1;
    }
  }

  &.active{
    border-color: #ffbe46;
    background: #fff4d5;
  }
}

.graph-box{
  border: 1px solid #ddd;
  margin-top: 25px;
  padding: 20px;
  background: ghostwhite;
}
