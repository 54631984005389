
$primary:       #147292 !default;
$secondary:     #f9b115 !default;
$success:       #198754FF !default;
$info:          #39f !default;
$warning:       #FFC107FF !default;
$danger:        #e55353 !default;
$light:         #F2FDDB !default;
$dark:          #212529FF !default;

$theme-colors: (
  //"primary-color":    $primary,
  //"secondary-color":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;


:root, [data-theme="light"] {
  --primary-color: #{$primary};
  --secondary-color: #{$secondary};
  --ligh-color: #{$light};

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }
}

[color="primary"] {
  background: var(--primary-color) !important;
  color: white !important;

  &:disabled{
    background: #ddd !important;
    color: #333 !important;
  }
}

[color="gradient"] {
  background: var(--gradient-primary) !important;
  color: white;
}

[color="mat-secondary-color"] {
  background: var(--secondary-color) !important;
  color: white;
}

.bg-primary {
  background: var(--primary-color) !important;
  color: #dddddd !important;
}

.bg-secondary {
  background: var(--secondary-color) !important;
  color: #333333;
}

.bg-secondary-subtle {
  background-color: #e5de96 !important;
}

.text-primary {
  color: var(--primary-color) !important;
}
