
@media(max-width: 576px) {
  .profile-image {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {

  .card-container > *:not(.circle-link) ,
  .card:not(.highlight-card) {
    margin: 8px 0;
  }

}

@media screen and (max-width: 992px) {
  .sm-hide{
    display: none !important;
  }
}


/* ----------- Non-Retina Screens ----------- */
@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 1) {
  .container {
    max-width: 1240px;
  }
}

/* ----------- Retina Screens ----------- */
@media screen
and (min-device-width: 1200px)
and (max-device-width: 1600px)
and (-webkit-min-device-pixel-ratio: 2)
and (min-resolution: 192dpi) {
  .container {
    max-width: 1240px;
  }
}

@media screen and (min-device-width: 1600px) {
  .container{
    max-width: 1640px !important;
  }
}
